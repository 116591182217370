<template lang="pug">
span
  .alert.alert-danger.trial.text-center.mb-0(v-if="isTrialPackage" role="alert") {{ $t('accountTrialExpired') }}
    router-link.btn.btn-default.brand-btn-link.font-weight-bold(
      v-if="!isPlanSettingsPage"
      :to="{ name: 'plan_settings' }"
    ) {{ $t('subscribe') }}
  .alert.alert-danger.text-center.mb-0(v-else role="alert") {{ $t('accountExpired') }}
    router-link.btn.btn-default.brand-btn-link.font-weight-bold(
      v-if="!isPlanSettingsPage"
      :to="{ name: 'plan_settings' }"
    ) {{ $t('extend') }}
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState(['account']),
      package() {
        return this.account.billing.package;
      },

      isTrialPackage() {
        return this.package === 'TRIAL';
      },

      isPlanSettingsPage() {
        return this.$route.name === 'plan_settings';
      },
    },
  };
</script>
